@import './themes';

@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }

  nb-card nb-list {
    @include nb-scrollbars(
      nb-theme(card-scrollbar-color),
      nb-theme(card-scrollbar-background-color),
      nb-theme(card-scrollbar-width));
  }

  .table {
    color: nb-theme(text-basic-color) !important;
  }

  nav {
    background-color: nb-theme(color-info-700);
  }

  .sidebar-toggle {
    color: nb-theme(text-alternate-color) !important;
  }

  nb-menu .menu-item .menu-icon {
    font-size: 1rem;
  }

  .nb-theme-default nb-menu .menu-item:last-child {
    position: relative;
    bottom: 0;
  }

  tr.ng2-smart-titles th {
    color: #ffffff !important;
    background-color: #0057c2;
    font-weight: bold;
  }

  .no-edit-column {
    font-weight: bold;
  }

  .buttons-container button[nbButton] {
    margin: 0.5rem;
  }

  .nb-theme-default nb-spinner.status-info {
    background-color: #0057c259 !important;
  }

  ::-webkit-scrollbar {
    width: 15px !important;
    height: 15px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #0057c2 !important;
  }

  ::-webkit-scrollbar-track {
    background: #0057c2;
  }
}
